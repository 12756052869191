import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gallery/allamericancalendars",
  "project": "All American Calendars",
  "projectID": "allamericancalendars",
  "projectURL": "https://www.allamericancalendars.com",
  "projectDate": "2020-03-21"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const GalleryImg = makeShortcode("GalleryImg");
const GalleryVid = makeShortcode("GalleryVid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, nesciunt illum quos id quo repellat dolorum totam sed voluptatem, numquam harum accusamus delectus, soluta laborum? Minima libero atque rerum deserunt? */
    }
    <Gallery mdxType="Gallery">
  <GalleryImg src="allamericancalendars_home.png" alt="Home page screenshot" title="Home" mdxType="GalleryImg" />
  <GalleryImg src="allamericancalendars_samples.png" alt="Samples page screenshot" title="Samples" mdxType="GalleryImg" />
  <GalleryImg src="allamericancalendars_samples-multi-year.png" alt="Multi-year samples page screenshot" title="Multi-year" mdxType="GalleryImg" />
  <GalleryVid file="allamericancalendars_section-navigation" mdxType="GalleryVid" />
  <GalleryImg src="allamericancalendars_contact-us.png" alt="Contact Us page screenshot" title="Contact Us" mdxType="GalleryImg" />
  <GalleryImg src="allamericancalendars_why-us.png" alt="Why Us page screenshot" title="Why Us" mdxType="GalleryImg" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      